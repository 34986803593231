const Range = ({ min, max, step, name, value, defaultValue, onChange }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium capitalize text-primary-text "
      >
        {name}{' '}
        <span className="border border-solid border-primary-border rounded-md p-1">{value}</span>
      </label>
      <input
        id={name}
        type="range"
        min={min}
        max={max}
        defaultValue={defaultValue}
        step={step}
        onChange={e => onChange(e.target.value)}
        className="w-full h-2 bg-primary-bg rounded-lg appearance-none cursor-pointer sm:w-72"
      />
    </div>
  );
};

export default Range;
