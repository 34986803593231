import Hero from 'components/Hero/Hero';
import Tts from 'components/Tts/Tts';

const Home = () => {
  return (
    <>
      <Hero>
        <Tts />
      </Hero>
    </>
  );
};

export default Home;
