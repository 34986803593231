import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiChevronUpDown } from 'react-icons/hi2';
import { BsCheck2 } from 'react-icons/bs';
import langs from 'data/languages.json';
import flags from '../../images/flags.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const LangSelect = ({ title, value, onChange, width }) => {
  return (
    <div>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-primary-text">
              {title}
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button
                className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-primary-text border border-solid border-primary-border focus:outline-none focus:ring-2 focus:ring-accent-hover sm:w-${width} sm:text-sm sm:leading-6 `}
              >
                <span className="flex items-center ">
                  <svg width="30px" height="20px">
                    <use href={flags + `#icon-${value.flag}`}></use>
                  </svg>
                  <span className="ml-3 block truncate">{value.language}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <HiChevronUpDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {langs.map(el => (
                    <Listbox.Option
                      key={el.language}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-accent text-white' : 'text-primary-text',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={el}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <svg width="30px" height="20px">
                              <use href={flags + `#icon-${el.flag}`}></use>
                            </svg>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-3 block truncate'
                              )}
                            >
                              {el.language}
                            </span>
                          </div>

                          {value.code === el.code ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-accent',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <BsCheck2
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default LangSelect;
