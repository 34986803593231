import * as Yup from 'yup';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TailSpin } from 'react-loader-spinner';
import toast from 'react-hot-toast';

const schema = Yup.object().shape({
  username: Yup.string().required('Username is required!').min(2).trim(),
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is required!')
    .trim(),
  phone: Yup.string(),
  password: Yup.string()
    .required('Password is required!')
    .min(6, 'Password must be at least 6 characters')
    .trim(),
});

const phoneNumberRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]{6,}\d$/;

const RegisterForm = ({ onSubmit, loading }) => {
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = (data, e) => {
    if (data.password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    if (data.phone !== '' && !phoneNumberRegex.test(data.phone)) {
      toast.error('The number must have at least 6 digits');
      return;
    }

    onSubmit({ ...data });
    reset();
    setConfirmPassword('')
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onFormSubmit)}>
      <div>
        <label
          htmlFor="username"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Username
        </label>
        <div className="relative mt-2">
          <input
            id="username"
            name="username"
            type="text"
            {...register('username')}
            autoComplete="username"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-primary-text shadow-sm outline-none ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {errors.username && (
            <span className="absolute text-xs text-error font-semibold">
              {errors.username?.message}
            </span>
          )}
        </div>
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Email address
        </label>
        <div className="relative mt-2">
          <input
            id="email"
            name="email"
            type="email"
            {...register('email')}
            autoComplete="email"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-primary-text shadow-sm outline-none ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {errors.email && (
            <span className="absolute text-xs text-error font-semibold">
              {errors.email?.message}
            </span>
          )}
        </div>
      </div>
      <div>
        <label
          htmlFor="phone"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Phone number (optional)
        </label>
        <div className="relative mt-2">
          <input
            id="phone"
            name="phone"
            type="number"
            {...register('phone')}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-primary-text shadow-sm outline-none ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {errors.phone && (
            <span className="absolute text-xs text-error font-semibold">
              {errors.phone?.message}
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-primary-text"
          >
            Password
          </label>
        </div>
        <div className="relative mt-2">
          <input
            id="password"
            name="password"
            type="password"
            {...register('password')}
            autoComplete="off"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-primary-text shadow-sm outline-none ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {errors.password && (
            <span className="absolute text-xs text-error font-semibold">
              {errors.password?.message}
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="confirm-password"
            className="block text-sm font-medium leading-6 text-primary-text"
          >
            Confirm password
          </label>
        </div>
        <div className="relative mt-2">
          <input
            id="confirm-password"
            name="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            autoComplete="off"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-primary-text shadow-sm outline-none ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
        >
          Sign up
          {loading && (
            <span className="ml-2">
              <TailSpin height="24" width="24" color="white" />
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
