import axios from 'axios';

const URL = 'https://google-tts-back.vercel.app/';

export const getTts = async () => {
  try {
    const result = await axios.get(`${URL}tts`);

    return result.data;
  } catch (error) {
    console.log(error);
  }
};
