import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, authError, isLoading, clearError } from '../../redux/auth';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import LoginForm from 'components/LoginForm/LoginForm';
import { passwordReset } from 'services/users';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is required!'),
});

const Login = () => {
  const [isPasswordForgot, setIsPasswordForgot] = useState(false);

  const dispatch = useDispatch();

  const signInError = useSelector(authError);
  const loading = useSelector(isLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (data, e) => {
    await passwordReset(data.email);
    reset();
    setIsPasswordForgot(false);
  };

  useEffect(() => {
    if (signInError) {
      dispatch(clearError());
    }
  }, [signInError, dispatch]);

  const onSubmit = user => {
    dispatch(login(user));
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-primary-text">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {!isPasswordForgot ? (
            <LoginForm onSubmit={onSubmit} loading={loading} />
          ) : (
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-primary-text"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register('email')}
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-primary-text outline-none shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                  />
                  {errors.email && (
                    <span className="absolute text-xs text-error font-semibold">
                      {errors.email?.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                >
                  Reset password
                </button>
              </div>
            </form>
          )}
          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <NavLink
              to="/register"
              className="font-semibold leading-6 text-accent hover:text-accent-hover"
            >
              Register now!
            </NavLink>
          </p>
        </div>
        <p className="mt-10 text-center text-sm text-gray-500">
          <button
            type="button"
            className="font-semibold leading-6 text-accent hover:text-accent-hover"
            onClick={() => setIsPasswordForgot(!isPasswordForgot)}
          >
            {!isPasswordForgot ? 'Forgot password?' : 'Go to login'}
          </button>
        </p>
      </div>
    </>
  );
};

export default Login;
