import { useDispatch, useSelector } from 'react-redux';
import { user, update } from '../../redux/auth';
import { NavLink } from 'react-router-dom';
import UpdateUserForm from 'components/UpdateUserForm/UpdateUserForm';

const Profile = () => {
  const dispatch = useDispatch();

  const { username, subscription, email, phone } = useSelector(user);

  const onSubmit = ({
    newEmail,
    newUsername,
    currentPassword,
    newPassword,
    newPhone,
  }) => {
    dispatch(
      update({
        email,
        ...(newEmail !== '' && newEmail !== email && { newEmail }),
        ...(newPhone !== '' && newPhone !== phone && { newPhone }),
        ...(newUsername !== '' && { newUsername }),
        ...(newPassword !== '' && { newPassword }),
        ...(currentPassword !== '' && { currentPassword }),
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-96 mx-auto px-6 py-24 ">
      <div className="flex flex-col w-full p-2 mb-6 bg-white z-50 rounded-md shadow-sm ring-1 ring-inset ring-primary-inset">
        <p className="text-base font-semibold">
          Your current subscription plan -{' '}
          <span className="text-accent">{subscription?.name}</span>
        </p>
        <p>
          You can change it{' '}
          <NavLink
            className=" text-accent max-w-max text-base font-semibold"
            to={'/pricing'}
          >
            here
          </NavLink>
        </p>
      </div>
      <UpdateUserForm
        username={username}
        phone={phone}
        email={email}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default Profile;
