import Subcsription from 'components/Subcsription/Subcsription';
import subscriptions from 'data/subsriptions.json';

const PriceList = () => {
  return (
    <div className="py-24 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
            Simple no-tricks pricing
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
            quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.
          </p>
        </div>
        <ul>
          {subscriptions.map(el => (
            <Subcsription key={el.id} subscription={el} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PriceList;
