import axios from 'axios';
import toast from 'react-hot-toast';

const URL = 'https://google-tts-back.vercel.app/user';

export const passwordReset = async email => {
  try {
    const result = await axios.post(
      'https://google-tts-back.vercel.app/auth/reset',
      {
        email,
      }
    );

    toast.success(result.data.message);
  } catch (error) {
    toast.error(error.message);
  }
};

export const getAllUsers = async () => {
  try {
    const result = await axios.get(`${URL}/users`);

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const changeUserDiscount = async ({ value, email }) => {
  try {
    const result = await axios.patch(`${URL}/discount`, { email, value });

    toast.success(result.data.message);
  } catch (error) {
    console.log(error);
  }
};

export const changeUserAccess = async ({ value, email }) => {
  try {
    const result = await axios.patch(`${URL}/access`, { email, value });

    toast.success(result.data.message);
  } catch (error) {
    console.log(error);
  }
};
