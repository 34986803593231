import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const schema = Yup.object().shape({
  newUsername: Yup.string()
    .min(2, 'Username must be at least 2 symbols')
    .trim(),
  newEmail: Yup.string().email('Email must be valid').trim(),
  newPhone: Yup.string(),
  currentPassword: Yup.string().trim(),
  newPassword: Yup.string().trim(),
});

const phoneNumberRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]{6,}\d$/;

const UpdateUserForm = ({ onSubmit, username, email, phone }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = (data, e) => {
    if (data.newPassword !== '' && data.newPassword.length < 6) {
      toast.error('Password must be at least 6 characters');
      return;
    }

    if (data.newPhone !== '' && !phoneNumberRegex.test(data.newPhone)) {
      toast.error('The number must have at least 6 digits');
      return;
    }

    onSubmit({ ...data });
    reset();
  };

  return (
    <form
      className="flex w-full max-w-96 flex-col gap-6"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <h2 className="text-base font-semibold leading-7 text-primary-text">
        Profile
      </h2>
      <div>
        <label
          htmlFor="username"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Username
        </label>
        <div className="w-full mt-2">
          <div className="flex relative w-full rounded-md shadow-sm ring-1 ring-inset ring-primary-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent sm:max-w-md">
            <input
              type="text"
              name="new-username"
              id="new-username"
              {...register('newUsername')}
              defaultValue={username}
              autoComplete="username"
              className="block w-full rounded-md border-0 py-1.5 px-3 outline-none text-primary-text shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm"
              placeholder={username}
            />
            {errors.newUsername && (
              <span className="absolute -bottom-4 text-xs text-error font-semibold">
                {errors.newUsername?.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Email
        </label>
        <div className="mt-2">
          <div className="flex relative rounded-md shadow-sm ring-1 ring-inset ring-primary-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent sm:max-w-md">
            <input
              type="email"
              name="new-email"
              id="new-email"
              defaultValue={email}
              {...register('newEmail')}
              autoComplete="email"
              className="block w-full rounded-md border-0 py-1.5 px-3 outline-none text-primary-text shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm"
              placeholder={email}
            />
            {errors.newEmail && (
              <span className="absolute -bottom-4 text-xs text-error font-semibold">
                {errors.newEmail?.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="phone"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Phone
        </label>
        <div className="mt-2">
          <div className="flex relative rounded-md shadow-sm ring-1 ring-inset ring-primary-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent sm:max-w-md">
            <input
              type="number"
              name="phone"
              id="phone"
              defaultValue={phone}
              {...register('newPhone')}
              autoComplete="phone"
              className="block w-full rounded-md border-0 py-1.5 px-3 outline-none text-primary-text shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm"
              placeholder="Phone number"
            />
            {errors.newPhone && (
              <span className="absolute -bottom-4 text-xs text-error font-semibold">
                {errors.newPhone?.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <h2 className="text-base font-semibold leading-7 text-primary-text ">
        Change password
      </h2>
      <div>
        <label
          htmlFor="new-password"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Current password
        </label>
        <div className="mt-2">
          <div className="flex relative rounded-md shadow-sm ring-1 ring-inset ring-primary-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent sm:max-w-md">
            <input
              type="password"
              name="current-password"
              id="current-password"
              {...register('currentPassword')}
              autoComplete="off"
              className="block w-full rounded-md border-0 py-1.5 px-3 outline-none text-primary-text shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm"
              placeholder="********"
            />
            {errors.currentPassword && (
              <span className="absolute -bottom-4 text-xs text-error font-semibold">
                {errors.currentPassword?.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="new-password"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          New password
        </label>
        <div className="mt-2">
          <div className="flex relative rounded-md shadow-sm ring-1 ring-inset ring-primary-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-accent sm:max-w-md">
            <input
              type="password"
              name="new-password"
              id="new-password"
              {...register('newPassword')}
              autoComplete="off"
              className="block w-full rounded-md border-0 py-1.5 px-3 outline-none text-primary-text shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm"
              placeholder="********"
            />
            {errors.newPassword && (
              <span className="absolute -bottom-4 text-xs text-error font-semibold">
                {errors.newPassword?.message}
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="block w-32 rounded-md bg-accent px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UpdateUserForm;
