import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import toast from 'react-hot-toast';
import {
  register,
  login,
  refreshUser,
  update,
  subscription,
} from './operations';

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = '';
};

const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['token'],
};

const initialState = {
  user: {
    username: '',
    email: '',
    phone: '',
    subscription: '',
    type: '',
    characterCounter: 0,
    discount: 1,
  },
  token: '',
  error: false,
  isLoading: false,
  isLoggedIn: false,
  isRefreshing: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError: state => {
      state.error = false;
    },
    logOut: state => {
      state.user = {
        username: '',
        email: '',
        phone: '',
        subscription: '',
      };
      state.token = '';
      state.isLoggedIn = false;
      toast.success('Logout successful');
      clearAuthHeader();
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, state => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.token = payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(register.rejected, state => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.token = payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(login.rejected, state => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(update.pending, state => {
        state.isLoading = true;
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.token = payload.token;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(update.rejected, state => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(subscription.pending, state => {
        state.isLoading = true;
      })
      .addCase(subscription.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.token = payload.token;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(subscription.rejected, state => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(refreshUser.pending, state => {
        state.isRefreshing = true;
      })
      .addCase(refreshUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(refreshUser.rejected, state => {
        state.isRefreshing = false;
      });
  },
});

export const { clearError, logOut } = authSlice.actions;

export const authReducer = persistReducer(persistConfig, authSlice.reducer);
