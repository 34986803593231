import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAllUsers,
  changeUserDiscount,
  changeUserAccess,
} from 'services/users';
import { getTts } from 'services/tts';
import { user } from '../../redux/auth';
import UserItem from 'components/UserItem/UserItem';

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [tts, setTts] = useState(null);
  const [sortBy, setSortBy] = useState('');

  const navigate = useNavigate();

  const currentUser = useSelector(user);

  useEffect(() => {
    if (currentUser.type !== 'Admin') {
      navigate('/');
    }
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const result = await getAllUsers();
        setUsers(result);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    getUsers();
  }, []);

  useEffect(() => {
    const getTtsStats = async () => {
      try {
        const result = await getTts();
        setTts(result);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    getTtsStats();
  }, []);

  const onUserBlock = async email => {
    try {
      await changeUserAccess({ email, value: 'block' });
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const onUserUnBlock = async email => {
    try {
      await changeUserAccess({ email, value: 'unblock' });
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSort = e => {
    setSortBy(e.target.value);
  };

  const handleDiscountChange = async (event, email) => {
    try {
      const value = event.target.value;

      await changeUserDiscount({ value, email });
    } catch (error) {
      console.log(error);
    }
  };

  const filteredUsers = users.filter(user => {
    switch (sortBy) {
      case 'spends':
        return user.totalSpends;
      case 'discount':
        return user.discount;
      case 'charactersCounter':
        return user.characterCounter;
      default:
        return true;
    }
  });

  filteredUsers.sort((a, b) => {
    switch (sortBy) {
      case 'spends':
        return b.totalSpends - a.totalSpends;
      case 'discount':
        return b.discount - a.discount;
      case 'charactersCounter':
        return b.characterCounter - a.characterCounter;
      default:
        return 0;
    }
  });

  return (
    <div className="flex gap-24 mx-auto max-w-7xl px-6 py-24">
      <div>
        <div className="mb-6 text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
          Tts stats
        </div>
        {tts && (
          <div>
            <div className="mb-6">Characters: {tts.characters}</div>
            {tts.voices.length > 0 && (
              <div className="flex flex-col gap-6 max-h-svh overflow-auto w-max">
                {tts.voices
                  .sort((a, b) => b.count - a.count)
                  .map(voice => (
                    <div
                      key={voice._id}
                      className="max-w-96 text-md rounded-md  bg-white py-1.5 pl-3 p-2 text-left text-primary-text border border-solid border-primary-border leading-8 text-gray-600 z-50"
                    >
                      <div>Person: {voice.person}</div>
                      <div>Name: {voice.name}</div>
                      <div>Language: {voice.languageCodes}</div>
                      <div>Count: {voice.count}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div>
        <div className="mb-6 text-3xl font-bold tracking-tight text-text-primary sm:text-4xl">
          Users
        </div>
        <div className="mb-6">
          <label htmlFor="sort-by">Sort by: - </label>
          <select id="sort-by" value={sortBy} onChange={handleSort}>
            <option value="">All</option>
            <option value="charactersCounter">Characters counter</option>
            <option value="spends">Spends</option>
            <option value="discount">Discount</option>
          </select>
        </div>
        <div className="flex flex-col gap-6 max-h-svh overflow-auto w-max">
          {filteredUsers.length > 0 &&
            filteredUsers.map(user => {
              return (
                <UserItem
                  key={user.email}
                  user={user}
                  onUserBlock={onUserBlock}
                  onUserUnBlock={onUserUnBlock}
                  onChange={handleDiscountChange}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Admin;
