import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiChevronUpDown } from 'react-icons/hi2';
import { BsCheck2 } from 'react-icons/bs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Select = ({ title, value, Icon, onChange, array }) => {
  return (
    <div>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-primary-text">
              {title}
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-primary-text border border-solid border-primary-border focus:outline-none focus:ring-2 focus:ring-accent-hover sm:text-sm sm:leading-6 sm:w-72 ">
                <span className="flex items-center">
                  {Icon ? (
                    <span className="pl-1">
                      <Icon size={14} />
                    </span>
                  ) : (
                    <img
                      src={require(`../../images/Avatars/${value.toLowerCase()}.jpg`)}
                      className="rounded-full"
                      width={24}
                      height={24}
                      alt={value}
                    />
                  )}
                  <span className="ml-3 block truncate">{value}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <HiChevronUpDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {array.map((el, i) => {
                    const { person, icon, ssmlGender, name, type } = el;

                    return (
                      <Listbox.Option
                        key={i}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-accent text-white'
                              : 'text-primary-text',
                            'relative cursor-default select-none py-1 pl-1 pr-9'
                          )
                        }
                        value={el}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center pl-2">
                              <span className="shrink">
                                {icon && <el.icon className="mr-2" size={14} />}
                              </span>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                <span className="flex gap-2 items-center justify-between">
                                  {person && (
                                    <img
                                      src={require(`../../images/Avatars/${person.toLowerCase()}.jpg`)}
                                      className="rounded-full"
                                      width={36}
                                      height={36}
                                      alt={value}
                                    />
                                  )}
                                  {person
                                    ? `${person} (${ssmlGender.toLowerCase()}) - ${type}`
                                    : name}
                                </span>
                              </span>
                            </div>

                            {value === person ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-accent',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <BsCheck2
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                            {value === name ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-accent',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <BsCheck2
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Select;
