import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TailSpin } from 'react-loader-spinner';

const schema = Yup.object().shape({
  email: Yup.string().email('Email must be valid').required('Email is required!'),
  password: Yup.string().required('Password is required!'),
});

const LoginForm = ({ onSubmit, loading }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = (data, e) => {
    onSubmit({ ...data });
    reset();
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onFormSubmit)}>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-primary-text"
        >
          Email address
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            {...register('email')}
            className="block w-full rounded-md border-0 px-3 py-1.5 text-primary-text outline-none shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {errors.email && (
            <span className="absolute text-xs text-error font-semibold">
              {errors.email?.message}
            </span>
          )}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-primary-text"
          >
            Password
          </label>
        </div>
        <div className="mt-2">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="off"
            {...register('password')}
            className="block w-full rounded-md border-0 px-3 py-1.5 text-primary-text outline-none shadow-sm ring-1 ring-inset ring-primary-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
          />
          {errors.password && (
            <span className="absolute text-xs text-error font-semibold">
              {errors.password?.message}
            </span>
          )}
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
        >
          Sign in
          {loading && (
            <span className="ml-2">
              <TailSpin height="24" width="24" color="white" />
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
