import { useState } from 'react';

const UserItem = ({ user, onChange, onUserBlock, onUserUnBlock }) => {
  const [voicesShown, setVoicesShown] = useState(false);

  return (
    <div
      key={user.email}
      className="relative max-w-96 text-md rounded-md  bg-white py-1.5 pl-3 p-2 text-left text-primary-text border border-solid border-primary-border leading-8 text-gray-600 z-50"
    >
      <div className="absolute flex flex-col gap-2 top-2 right-2">
        <button
          type="button"
          onClick={() => onUserBlock(user.email)}
          className="text-error"
        >
          Block
        </button>
        <button
          type="button"
          onClick={() => onUserUnBlock(user.email)}
          className="text-accent"
        >
          Unblock
        </button>
      </div>
      <div className="flex gap-3">
        <div>{user.username}</div>
        <div>-</div>
        <div>{user.email}</div>
      </div>
      <div>Spends: {user.totalSpends}$</div>
      <div>
        <label htmlFor={`discount-${user.email}`}>Discount:</label>
        <select
          id={`discount-${user.email}`}
          defaultValue={user.discount}
          onChange={event => onChange(event, user.email)}
        >
          <option value={1.0}>0%</option>
          <option value={1.05}>5%</option>
          <option value={1.1}>10%</option>
          <option value={1.15}>15%</option>
          <option value={1.2}>20%</option>
          <option value={1.25}>25%</option>
          <option value={1.3}>30%</option>
          <option value={1.35}>35%</option>
          <option value={1.4}>40%</option>
          <option value={1.45}>45%</option>
          <option value={1.5}>50%</option>
          <option value={1.55}>55%</option>
          <option value={1.6}>60%</option>
          <option value={1.65}>65%</option>
          <option value={1.7}>70%</option>
          <option value={1.75}>75%</option>
          <option value={1.8}>80%</option>
          <option value={1.85}>85%</option>
          <option value={1.9}>90%</option>
          <option value={1.95}>95%</option>
        </select>
      </div>
      <div>
        <div>Subscription - {user.subscription.name}</div>
      </div>
      <div>Characters counter - {user.characterCounter}</div>

      <div>
        {user.voices.length > 0 && (
          <div>
            <div>
              Voices used -{' '}
              <button
                type="button"
                onClick={() => setVoicesShown(!voicesShown)}
              >
                Show
              </button>
            </div>
            {voicesShown &&
              user.voices.map(voice => (
                <div
                  key={voice._id}
                  className="rounded-md  bg-white py-1.5 pl-3 p-2 text-left text-primary-text border border-solid border-primary-border leading-8 text-gray-600"
                >
                  <div>Person: {voice.person}</div>
                  <div>Name: {voice.name}</div>
                  <div>Language: {voice.languageCodes}</div>
                  <div>Count: {voice.count}</div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserItem;
