import {
  BsMicMute,
  BsHeadphones,
  BsPhone,
  BsSmartwatch,
  BsSpeaker,
  BsCarFront,
  BsHdd,
  BsTelephone,
  BsLaptop,
} from 'react-icons/bs';

export const audioDevice = [
  { name: 'Without effect', value: null, icon: BsMicMute },
  {
    name: 'Smart watch or wearable',
    value: 'wearable-class-device',
    icon: BsSmartwatch,
  },
  { name: 'Smartphone', value: 'handset-class-device', icon: BsPhone },
  {
    name: 'Headphones or earbuds',
    value: 'headphone-class-device',
    icon: BsHeadphones,
  },
  {
    name: 'Small home speaker',
    value: 'small-bluetooth-speaker-class-device',
    icon: BsLaptop,
  },
  {
    name: 'Smart home speaker',
    value: 'medium-bluetooth-speaker-class-device',
    icon: BsHdd,
  },
  {
    name: 'Home entertainment system or smart TV',
    value: 'large-home-entertainment-class-device',
    icon: BsSpeaker,
  },
  {
    name: 'Car speaker',
    value: 'large-automotive-class-device',
    icon: BsCarFront,
  },
  {
    name: 'Interactive Voice Response (IVR) system',
    value: 'telephony-class-application',
    icon: BsTelephone,
  },
];
