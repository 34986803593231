import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

const Layout = () => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div>
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <main>
            <Outlet />
          </main>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
